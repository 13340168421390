$accent_color:#81c343;
$white:#ffffff;
$content_color:#e8e8e8;
$header_color:#131418;
$main_font:'Muli', sans-serif;

$font_color1:#656c73;

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim{
    transition: 0.3s all ease;
}

.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}

body{
    font-family: $main_font;
    margin: 0;
    background: $content_color;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
        font-weight: 400;
    }
    input{
        outline: none;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}

.page{
    overflow: hidden;
}

.default_font{
    font-size: 15px;
    line-height: 31px;
    color: $font_color1;
    @media only screen and (max-width: 1500px) {
        font-size: 13px;
        line-height: 29px;
    }
    h1{
        font-size: 43px;
        line-height: 60px;
        @media only screen and (max-width: 1500px) {
            font-size: 25px;
            line-height: 35px;
        }
        span{
            font-size: 31px;
            @media only screen and (max-width: 1500px) {
                font-size: 25px;
                line-height: 35px;
            }
        }
    }
    h2{
        font-size: 25px;
        line-height: 35px;
        @media only screen and (max-width: 1500px) {
            font-size: 23px;
            line-height: 33px;
        }
    }
    h3{
        font-size: 23px;
        line-height: 33px;
        @media only screen and (max-width: 1500px) {
            font-size: 21px;
            line-height: 31px;
        }
    }
    h4{
        font-size: 21px;
        line-height: 31px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h5{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 26px;
        }
    }
    h6{
        font-size: 16px;
        line-height: 26px;
        @media only screen and (max-width: 1500px) {
            font-size: 15px;
            line-height: 31px;
        }
    }
    ul{
        padding-left: 18px;
        list-style: disc;
        li{
            p{
                margin-bottom: 0;
            }
        }
        > li{
            color: $accent_color;
            > ul{
                list-style: none;
                padding-left: 0;
                > li{
                    &:last-child{
                        margin-bottom: 15px;
                    }
                    p{
                        &:before{
                            content: '-';
                            color: $accent_color;
                            margin-right: 10px;
                        }
                    }
                }
            }
           > p{
               color: $font_color1;
           }
        }
    }
    ol{
        padding-left: 18px;
    }
    a{
        color: $accent_color;
    }
    h1,h2,h3,h4,h5,h6{
        color: $header_color;
    }
    h1,h2,h3,h4,h5,h6,ul,a,p,img{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    table{
        width: 100%;
        border: 1px solid #cccccc;
        tbody{
            tr{
                border-bottom: 1px solid #cccccc;
                &:last-child{
                    border: none;
                }
                &:first-child{
                    td{
                        color: $white;
                        font-size: 13px;
                        border-right:none;
                        &:nth-child(odd){
                            background: #44464a;
                        }
                        &:nth-child(even){
                            background: #28292e;
                        }
                    }
                }
                td{
                    font-size: 14px;
                    padding: 11px 20px;
                    border-right: 1px solid #cccccc;
                    color: #676c74;
                    &:nth-child(2){
                        background:$white;
                    }
                    &:last-child{
                        border-right: none;
                    }
                }
            }
        }
    }
}

.more_btn{
    display: inline-block;
    font-size: 12.5px;
    line-height: 12.5px;
    //text-transform: uppercase;
    padding: 18px 46px;
    border: 1px solid $accent_color;
    color: $header_color;
    @include anim;
    border-radius: 7px;
    font-weight: 700;
    &:hover{
        background: $accent_color;
        color: $header_color;
    }
    &.active{
        background: $accent_color;
        color: $header_color;
    }
}

.more_btn2{
    display: inline-block;
    background:#e52638;
    color: $white;
    padding: 20px 38px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 13px;
    text-decoration: none;
    @include anim;
    &:hover{
        background: $white;
        color: #e52638;
    }
}

header.header{
    position: relative;
    z-index: 5;
    .more_btn{
        color: $font_color1;
    }
    background: #0e0f11;
    @media only screen and (max-width: 991px) {
        padding: 6px 0;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
    }
    div{
        &.logo_box{
            padding-top: 55px;
            position: relative;
            div.rwd_btn{
                width: 30px;
                position: absolute;
                top: 6px;
                right: 0;
                display: none;
                @media only screen and (max-width: 991px) {
                    display: block;
                }
                > div{
                    width: 100%;
                    height: 3px;
                    margin-bottom: 3px;
                    background: $accent_color;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            img{
                max-width: 100%;
                height: auto;
                @media only screen and (max-width: 991px) {
                    max-width: 100px;
                    height: auto;
                }
            }
            @media only screen and (max-width: 1500px) {
                padding-top: 35px;
            }
            @media only screen and (max-width: 1200px) {
                padding-top: 40px;
            }
            @media only screen and (max-width: 991px) {
                padding-top: 0;
            }
        }
        &.header_navigation{
            text-align: right;
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    //margin-right: 70px;
                    margin-right: 40px;
                    position: relative;
                    @media only screen and (max-width: 1500px) {
                        margin-right: 20px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 20px;
                    }
                    &.go_to_shop{
                        margin-right: 20px;
                        @media only screen and (max-width: 1500px) {
                            .more_btn{
                                font-size: 10.5px;
                                line-height: 10.5px;
                            }
                        }
                        @media only screen and (max-width: 1200px) {
                            .more_btn{
                                padding: 18px 26px;
                            }
                        }
                    }
                    &.langs{
                        div.dropdown{
                            &.open{
                                .dropdown-toggle{
                                    &.btn-primary{
                                        border: 1px solid #81c343;
                                        background: #81c343;
                                    }
                                }
                                .dropdown-menu{
                                    min-width: auto;
                                    width: 100%;
                                    overflow: hidden;
                                    background: #d4d5d6;
                                    padding: 0;
                                    border: none;
                                    > li{
                                        margin-right: 0;
                                        display: block;
                                        width: 100%;
                                        > a{
                                            display: block;
                                            color: #656c73;
                                            padding: 3px 18px;
                                            @include anim;
                                            &:hover{
                                                background: #81c343;
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                            .btn{
                                display: inline-block;
                                font-size: 12.5px;
                                line-height: 12.5px;
                                padding: 18px;
                                border: 1px solid #81c343;
                                color: #656c73;
                                transition: 0.3s all ease;
                                border-radius: 7px;
                                font-weight: 700;
                                background: transparent;
                                @media only screen and (max-width: 1500px) {
                                   font-size: 10.5px;
                                   line-height: 10.5px;
                                }
                                &:hover, &:focus{
                                    border: 1px solid #81c343;
                                    background: #81c343;
                                }
                            }
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    &.menu_box{
                        nav.menu{
                            > ul{
                                > li{
                                    &:hover{
                                        > ul{
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    position: relative;
                                    > ul{
                                        width: 370px;
                                        opacity: 0;
                                        visibility: hidden;
                                        position: absolute;
                                        top: 100%;
                                        left: 0;
                                        text-align: left;
                                        background: #33353b;
                                        @include anim;
                                        @media only screen and (max-width: 1500px) {
                                            width: 300px;
                                        }
                                        > li{
                                           border-top: 1px solid #3d4046;
                                           width: 100%;
                                           margin-right: 0;
                                           @include anim;
                                           background-repeat: no-repeat;
                                           background-position: right 27px center;
                                           &.a{
                                            background-image: url('./../img/menu_more_arr.png');
                                           }
                                           &:hover{
                                              background-color: #28292e;
                                              > ul{
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                           }
                                            > div{
                                                a{
                                                   // white-space: nowrap;
                                                    display: inline-block;
                                                    padding: 20px 30px;
                                                    font-size: 13px;
                                                    color: #e8e8e8;
                                                    @media only screen and (max-width: 1500px) {
                                                        font-size: 11px;
                                                        padding: 15px 25px;
                                                    }
                                                   &:before{
                                                       content: '•';
                                                       color: $accent_color;
                                                       margin-right: 10px;
                                                   }
                                                }
                                            }
                                            > ul{
                                                min-height: 100%;
                                                @include anim;
                                                opacity: 0;
                                                visibility: hidden;
                                                position: absolute;
                                                top: 0;
                                                left: 100%;
                                                background: #28292e;
                                                > li{
                                                    margin-right: 0;
                                                    border-top: 1px solid #3d4046;
                                                    width: 100%;
                                                    @include anim;
                                                    &:hover{
                                                        background-color: #202125;
                                                     }
                                                    > div{
                                                        a{
                                                            display: block;

                                                            display: inline-block;
                                                            padding: 20px 30px;
                                                            font-size: 13px;
                                                            color: #e8e8e8;
                                                            @media only screen and (max-width: 1500px) {
                                                                font-size: 11px;
                                                                padding: 15px 25px;
                                                            }
                                                           &:before{
                                                               content: '•';
                                                               color: $accent_color;
                                                               margin-right: 10px;
                                                           }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    > div{
                                        padding: 84px 0;
                                        position: relative;
                                        @media only screen and (max-width: 1500px) {
                                            padding: 54px 0;
                                        }
                                        &:after{
                                            content: '';
                                            width: 0%;
                                            left: 50%;
                                            top: 100%;
                                            position: absolute;
                                            margin-top: -1px;
                                            height: 2px;
                                            background: $accent_color;
                                            @include anim;
                                        }
                                        &:hover{
                                            &:after{
                                                width: 100%;
                                                left: 0;
                                            }
                                            a{
                                                color: #d4d5d6;
                                            }
                                        }
                                        &.on{
                                            &:after{
                                                width: 100%;
                                                left: 0;
                                            }
                                            a{
                                                color: #d4d5d6;
                                            }
                                        }
                                        > a{
                                            color: $font_color1;
                                            @include anim;
                                            text-transform: uppercase;
                                            font-size: 12.5px;
                                            line-height: 12.5px;
                                            @media only screen and (max-width: 1500px) {
                                                font-size: 10.5px;
                                                line-height: 10.5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.slider{
    height: 480px;
    width: 100%;
    position: relative;
    background: #181a1d;
    @media only screen and (max-width: 1500px) {
        height: 370px;
    }
    @media only screen and (max-width: 991px) {
        height: 100vh;
    }
    > ul{
        @include fill;
        > li{
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding-top: 50px;
            @media only screen and (max-width: 1500px) {
                padding-top: 30px;
            }
            div{
                &.slider_content{
                    @media only screen and (max-width: 991px) {
                        padding-top: 44.77px;
                    }
                    > div{
                        div.default_font{
                            color: $white;
                            margin-bottom: 20px;
                            h1{
                                color: $white;
                            }
                            ul{
                                li{
                                    color: #7f838a;
                                }
                            }
                        }
                        font-size: 16px;
                        line-height: 26px;
                        color: #7f838a;
                        @media only screen and (max-width: 1500px) {
                            font-size: 13px;
                            line-height: 23px;
                        }
                        &.desc{
                            margin-bottom: 60px;
                            @media only screen and (max-width: 1500px) {
                                margin-bottom: 30px;
                            }
                        }
                    }
                    .slider_image{
                        text-align: right;
                        height: 210px;
                        width: 100%;
                        position: relative;
                        color: $white;
                        white-space: nowrap;
                        width: 355px;
                        display: inline-block;
                        @media only screen and (max-width: 1500px) {
                            height: 140px;
                            width: 280px;
                        }
                        @media only screen and (max-width: 1200px) {
                            height: 125px;
                        }
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                        img{
                            margin-top: -34px;
                            margin-right: -60px;
                            position: relative;
                            z-index: 2;
                            max-height: 100%;
                            width: auto;
                            @media only screen and (max-width: 1500px) {
                                margin-top: -15px;
                            }
                            @media only screen and (max-width: 1200px) {
                                margin-right: -20px;
                            }
                        }
                        p{
                            text-align: center;
                            padding-left: 45px;
                            padding-top: 11px;
                            padding-bottom: 11px;
                            padding-right: 40%;
                            width: 100%;
                            background-image: url('./../img/sl_desc_bg.png');
                            background-repeat: no-repeat;
                            background-position: left center;
                            background-size: auto 100%;
                        }
                    }
                }
            }
        }
    }
    div.slider_nav{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 30px;
        .slider_dots{
            width: 100%;
            text-align: right;
            position: relative;
            &:after{
                content:'';
                height: 1px;
                width: 100%;
                background: #5d5f62;
                position: absolute;
                top: 48%;
                left: 0;
            }
            ul{
                position: relative;
                z-index: 2;
                display: inline-block;
                background: #181a1d;
                li{
                    display: inline-block;
                    vertical-align: top;
                    width: 11px;
                    height: 0;
                    padding-bottom: 7px;
                    border: 2px solid #5d5f62;
                    border-radius: 50%;
                    @include anim;
                    margin-left: 10px;
                    cursor: pointer;
                    &.on{
                         background: #5d5f62
                    }
                }
            }
        }
    }
}

.slider_content{
    > div.desc{
        > ul{
            padding-left: 18px;
            @media only screen and (max-width: 991px) {
                padding-left: 0;
            }
            > li{
                padding-left: 48px;
                min-height: 25px;
                background-repeat: no-repeat;
                background-position: left center;
                background-image: url('./../img/list_mark.png');
                margin-bottom: 19px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}

section.search{
    background: $white;
    padding: 37px 0;
    h2{
        padding-top: 20px;
        font-weight: inherit;
        font-size: 18px;
        font-weight: 700;
        @media only screen and (max-width: 991px) {
            padding-top: 0;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    form{
        padding-left: 5%;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
        div.holder{
            border: 1px solid #e4e4e4;
            position: relative;
            overflow: hidden;
            border-radius: 7px;
            -webkit-box-shadow: 0px 10px 38px 7px rgba(228,228,228,0.7);
            -moz-box-shadow: 0px 10px 38px 7px rgba(228,228,228,0.7);
            box-shadow: 0px 10px 38px 7px rgba(228,228,228,0.7);
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $font_color1;
              }
              ::-moz-placeholder { /* Firefox 19+ */
                color: $font_color1;
              }
              :-ms-input-placeholder { /* IE 10+ */
                color: $font_color1;
              }
              :-moz-placeholder { /* Firefox 18- */
                color: $font_color1;
              }
            input{
                color: #676c74;
                border: none;
                &.search_input{
                    width: 100%;
                    height: 63px;
                    padding-left: 27px;
                    font-size: 16px;
                }
                &.search_submit{
                    padding: 0;
                    width: 127px;
                    font-size: 14.5px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    font-weight: 800;
                    text-align: left;
                    background-size: 35px auto;
                    background-repeat: no-repeat;
                    background-position: center right 30px;
                    background-image: url('./../img/sub_arr.png');
                    @include anim;
                    background-color: transparent;
                    @media only screen and (max-width: 991px) {
                        position: relative;
                        width: 100%;
                        height: 63px;
                        padding-left: 27px;
                    }
                    &:hover{
                        background-position: center right 25px;
                    }
                }
            }
        }
    }
}

.calc{
    background-color: #2e2f36;
    background-repeat: no-repeat;
    background-position: right top;
    background-image: url('./../img/calc_bg.jpg');
    padding: 37px 0;
    font-size: 13px;
    line-height: 25px;
    color: $white;
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    h2{
        padding-top: 5px;
        font-weight: inherit;
        font-size: 33px;
        font-weight: 700;
        color: $white;
        margin-bottom: 27px;
        @media only screen and (max-width: 1500px) {
            font-size: 26px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 21px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 0;
            margin-bottom: 20px;
            text-align: center;
        }
    }
    form{
        padding-left: 5%;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
        div.calc_holder{
            background: $content_color;
            padding: 22px 28px;
            width: 100%;
            border-radius: 7px;
            @media only screen and (max-width: 991px) {
                padding: 15px;
                margin-top: 20px;
            }
            > div{
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                &:nth-child(1){
                    width: 40%;
                    padding-right: 30px;
                    @media only screen and (max-width: 991px) {
                        padding-right: 0;
                        width: 100%;
                        padding-bottom: 15px;
                    }
                }
                &:nth-child(2){
                    width: 40%;
                    padding-right: 40px;
                    @media only screen and (max-width: 991px) {
                        padding-right: 0;
                        width: 100%;
                        padding-bottom: 15px;
                    }
                    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $font_color1;
                      }
                      ::-moz-placeholder { /* Firefox 19+ */
                        color: $font_color1;
                      }
                      :-ms-input-placeholder { /* IE 10+ */
                        color: $font_color1;
                      }
                      :-moz-placeholder { /* Firefox 18- */
                        color: $font_color1;
                      }
                }
                &:nth-child(3){
                    width: 20%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
                label{
                    display: block;
                    font-size: 12px;
                    font-weight: 600;
                    color: $font_color1;
                    text-align: left;
                }
                .calc_input{
                    height: 45px;
                    background: #d4d5d6;
                    border: none;
                    border-radius: 7px;
                    padding: 0 20px;
                    width: 100%;
                }
                .calc_sub_input{
                    display: inline-block;
                    font-size: 12.5px;
                    line-height: 12.5px;
                    padding: 18px 36px;
                    border: 1px solid $accent_color;
                    color: $header_color;
                    @include anim;
                    border-radius: 7px;
                    font-weight: 700;
                    background: transparent;
                    @media only screen and (max-width: 1500px) {
                        width: 100%;
                        padding: 0;
                        height: 50px;
                        display: block;
                    }
                    &:hover{
                        background: $accent_color;
                        color: $header_color;
                    }
                }
                .chosen-container{
                    width: 100% !important;
                    &.chosen-container-single{
                        .chosen-default{
                            color: $font_color1;
                        }
                    }
                    .chosen-drop{
                        background: #d4d5d6;
                        border: none;
                    }
                    .chosen-results{
                        color: $font_color1;
                        li{
                            text-align: left;
                            &.highlighted{
                                background: $accent_color;
                            }
                            &.no-results{
                                background: $accent_color;
                                color: $white;
                            }
                        }
                    }
                    .chosen-single{
                        height: 45px;
                        padding-left: 19px;
                        background: #d4d5d6;
                        box-shadow: none;
                        border: none;
                        span{
                            text-align: left;
                            padding-top: 10px;
                            color: $font_color1;
                        }
                        div{
                            width: 50px;
                            b{
                                background-position: center;
                                background-image: url('./../img/select_arr.png');
                            }
                        }
                    }
                }
            }
        }
    }
}

.section_header{
    margin: 55px 0;
    @media only screen and (max-width: 1500px) {
        margin: 35px 0 40px 0;
    }
    div.default_font{
        position: relative;
        padding-right: 100px;
        color: $header_color;
        display: inline-block;
        @media only screen and (max-width: 991px) {
            display: block;
            text-align: center;
            padding-right: 0;
        }
        &:after{
            content: '';
            width: 69px;
            height: 2px;
            background: $accent_color;
            position: absolute;
            top: 50%;
            right: 0;
            @media only screen and (max-width: 991px) {
                width: 100%;
                top: 100%;
                margin-top: 15px;
            }
        }
    }
}

section.products{
    background: #cbccce;
    padding-bottom: 25px;;
    &.main_p{
        background: $content_color;
        div.category_box{
            &:hover{
                .image{
                    .cover{
                        .bg{
                            opacity: 0.7;
                            visibility: visible;
                        }
                        .vertical_align{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        .image{
            border: 2px solid $white;
            position: relative;
            background: transparent !important;
            div.cover{
                .bg{
                    @include fill;
                    background:$accent_color;
                    @include anim;
                    visibility: hidden;
                    opacity: 0;
                }
                .vertical_align{
                    visibility: hidden;
                    opacity: 0;
                    z-index: 3;
                    text-align: center;
                }
                img{
                    position: relative;
                }
            }
        }
    }
    div.categories{
        .row{
            > div{
                margin-bottom: 30px;
            }
        }
        div.category_box{
            width: 100%;
            display: table;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            &:hover{
                > div.image{
                    transform: scale(1.1);
                    background: $accent_color;
                }
            }
            > div{
                display: table-cell;
                vertical-align: middle;
                @media only screen and (max-width: 991px) {
                    display: block;
                }
                height: 100%;
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
                color: $header_color;
                &.image{
                    @include anim;
                    width: 45%;
                    height: 0;
                    padding-bottom: 45%;
                    background-color: $content_color;
                    border-radius: 50%;
                    position: relative;
                    overflow: hidden;
                    @media only screen and (max-width: 991px) {
                        margin: 0 auto 30px auto;
                    }
                    > div{
                        @include fill;
                        @include anim;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                    }
                }
                &.desc{
                    position: relative;
                    width: 55%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        .vertical_align{
                            position: relative;
                            > div{
                                display: block;
                                > div{
                                    display: block;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    > div{
                        padding-left: 30px;
                    }
                }
                p{
                    font-weight: inherit;
                }
            }
        }
        div.jump_to_store{
            margin-top: 10px;
            color: $font_color1;
            background: $content_color;
            padding: 24px 32px;
            border-radius: 7px;
            background-repeat: no-repeat;
            background-position: right 30px center;
            background-image: url('./../img/sub_arr.png');
            @include anim;
            &:hover{
                background-position: right 20px center;
            }
            h2{
                font-weight: 700;
                color: $header_color;
                margin-bottom: 7px;
            
            }
        }
    }
}

section.about{
    display: table;
    width: 100%;
    height: 418px;
    @media only screen and (max-width: 991px) {
        height: auto;
        display: block;
    }
   > div{
        display: table-cell;
        vertical-align: top;
        height: 100%;
        padding-top: 70px;
        @media only screen and (max-width: 1500px) {
            padding-top: 43px;
        }
        @media only screen and (max-width: 991px) {
            display: block;
            padding-top: 30px;
        }
        &.desc1{
            width: 62%;
            padding-right: 160px;
            @media only screen and (max-width: 1500px) {
                padding-right: 130px;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding-right: 15px;
                padding-left: 15px !important;
                padding-bottom: 30px;
            }
            .section_header{
                margin-top: 0;
            }
            > div.default_font{
                margin-bottom: 30px;
            }
        }
        &.desc2{
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('./../img/home_img1.jpg');
            width: 38%;
            @media only screen and (max-width: 991px) {
                padding-top: 30px;
                padding-bottom: 30px;
                width: 100%;
                padding-left: 15px;
                padding-right: 15px !important;
            }
            > div{
                display: inline-block;
                vertical-align: top;
                white-space: normal;
                &.default_font{
                    width: calc(100% - 39%);
                    color: $white;
                    padding-left: 30px;
                    h2{
                        color: inherit;
                    }
                }
            }
            
        }
    }
}

.cert_box{
    display: inline-block;
    vertical-align: top;
    width: 39%;
    height: 0;
    padding-bottom: 55%;
    position: relative;
    margin-left: -20%;
    @media only screen and (max-width: 991px) {
        margin-left: 0;
    }
    > div{
        @include fill;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}

.banner{
    width: 100%;
    height: 0;
    padding-bottom: 18%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    .banner_desc{
        color: $white;
        font-size: 25px;
        line-height: 30px;
        font-weight: 700;
        @media only screen and (max-width: 1500px) {
            font-size: 23px;
            line-height: 28px;
        }
        img{
            max-width: 100%;
            height: auto;
        }
        p,img{
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
            
            }
            &:last-child{
                margin-bottom: 0;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    img{
        max-width: 100%;
        height: auto;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
    @media only screen and (max-width: 991px) {
        padding-bottom: 300px;
        text-align: center;
    }
    .text-right{
        padding-top: 3%;
        @media only screen and (max-width: 991px) {
            padding-top: 0;
        }
    }
}

footer.footer{
    padding:0 0 50px 0;
    background: #1f2126;
    @media only screen and (max-width: 991px) {
        padding: 0 0 30px 0;   
        text-align: center;
    }
    div.footer_desc{
        padding: 30px 0;
        background:#2e2f36;
        div.default_font{
            color: $white;
        }
    }
    > .container{
        padding-top: 50px;
        @media only screen and (max-width: 991px) {
            display: flex;
            flex-flow: column;
            > div.row{
                align-items: stretch;
                &:first-child{
                    order: 2;
                    margin-bottom: 0;
                }
                &:last-child{
                    order: 1;
                    margin-bottom: 30px;
                }
            }
            padding-top: 30px;
        }   
        > .row{
            &:first-child{
                color: $white;
                font-size: 15px;
                margin-bottom: 20px;
                ul{
                    li{
                        display: inline-block;
                        vertical-align: middle;
                        img{
                            margin-left: 15px;
                        }
                    }
                }
            }
            &:last-child{
                .menu{
                    ul{
                        > li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 30px;
                            @media only screen and (max-width: 991px) {
                                padding-right: 0;
                                padding-bottom: 15px;
                                width: 100%;
                                text-align: center;
                            }
                            &:last-child{
                                margin-right:0;
                            }
                        }
                    }
                }
                p,a{
                    color: $font_color1;
                    font-size: 12.5px;
                    cursor: pointer;
                    display: inline-block;
                    @include anim;
                    &:hover{
                        color: $accent_color;
                    }
                }
            }
        }
    }
}

section.top{
    padding: 50px 0;
    background: #212227;
    @media only screen and (max-width: 1500px) {
        padding:30px 0;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 44.77px;
    }
    header.page_header{
        div.default_font{
            h1{
                color: $white;
            }
        }
    }
    .search2{
        div.holder{
            position: relative;
            overflow: hidden;
            border-bottom: 1px solid rgba(208, 208, 208, 0.15);
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #676c74;;
              }
              ::-moz-placeholder { /* Firefox 19+ */
                color: #676c74;
              }
              :-ms-input-placeholder { /* IE 10+ */
                color: #676c74;;
              }
              :-moz-placeholder { /* Firefox 18- */
                color: #676c74;;
              }
            input{
                background: transparent;
                border: none;
                height: 50px;
                color: #676c74;
                &.search_input2{
                    width: 100%;
                    padding-right: 100px;
                    font-size: 16px;
                }
                &.search_submit2{
                    width: 100px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-size: 35px auto;
                    background-image: url('./../img/sub_arr.png');
                    font-size: 15px;
                    font-weight: 800;
                    text-align: left;
                    @include anim;
                    &:hover{
                        color: $accent_color;
                    }
                }
            }
        }
    }
}

section.cert{
    padding-bottom: 50px;
    div.cert_list{
        ul{
            li{
                width: 23.4%;
                display: inline-block;
                vertical-align: top;
                margin-right: 2.1%;
                margin-bottom: 30px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                    text-align: center;
                }
                &:nth-child(4n), &:last-child{
                    margin-right: 0;
                }
                div.default_font{
                    color: $header_color;
                    margin-bottom: 20px;
                }
                div.image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 143%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 7px;
                }
            }
        }
    }
}

section.contact{
    div.contact_box{
        margin-bottom: 20px;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        div.default_font{
            line-height: 26px;
        }
        h3{
            color: $header_color;
            font-size: 15px;
            font-weight: 700;
            line-height: 26px;
        }
    }
    div.map{
        div.map_box{
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 80%;
            @media only screen and (max-width: 991px) {
                padding-bottom: 300px;
                margin-bottom: 30px;
            }
            iframe{
                border: none;
                @include fill;
            }
        }
    }
}

.desc_text{
    padding-top: 70px;
    padding-bottom: 70px;
    @media only screen and (max-width: 1500) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @media only screen and (max-width: 991px) {
        overflow-x: scroll;
    }
}

.sites{
    padding-bottom: 60px;
    .pagination1{
        padding: 9px 33px;
        background: #28292e;
       > ul{
            width: 100%;
            display: table;
            > li{
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                &:first-child, &:last-child{
                    width: 27px;
                }
                .numbers{
                    > ul{
                       > li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 26px;
                            width: 35px;
                            height: 0;
                            padding-bottom: 35px;
                            position: relative;
                            border-radius: 50%;
                            @include anim;
                            &:hover, &.active {
                                background: #42444b;
                                a{
                                    color: $white;
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            a{
                                @include anim;
                                color: $font_color1;
                                font-size: 13px;
                            }
                        }
                    }
                }
                .arr{
                    cursor: pointer;
                    width: 100%;
                    height: 14px;
                    background-repeat: no-repeat;
                    background-position: center;
                    position:relative;
                    @include anim;
                    &.arr_left{
                        background-image: url('/img/sites_arr_left.png');
                        &:hover{
                            background-image: url('/img/sites_arr_left_a.png');
                        }
                    }
                    &.arr_right{
                        background-image: url('/img/sites_arr_right.png');
                        &:hover{
                            background-image: url('/img/sites_arr_right_a.png');
                        }
                    }

                    a {
                        position:absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                    }
                }
            }
        }
    }
}

.rwd_menu{
    background: #0e0f11;
    //height: 100%;
    top: 44.77px;
    width: 100%;
    right: -100%;
    position: fixed;
    z-index: 10;
    overflow-y: scroll;
    bottom: 0;
    .langs{
        div.dropdown{
            &.open{
                .dropdown-toggle{
                    &.btn-primary{
                        border: 1px solid #81c343;
                        background: #81c343;
                    }
                }
                .dropdown-menu{
                    min-width: auto;
                    width: 100%;
                    overflow: hidden;
                    background: #d4d5d6;
                    padding: 0;
                    border: none;
                    > li{
                        margin-right: 0;
                        display: block;
                        width: 100%;
                        > a{
                            display: block;
                            color: #656c73;
                            padding: 3px 18px;
                            @include anim;
                            &:hover{
                                background: #81c343;
                                color: $white;
                            }
                        }
                    }
                }
            }
            .btn{
                display: inline-block;
                font-size: 12.5px;
                line-height: 12.5px;
                padding: 18px;
                border: 1px solid #81c343;
                color: $white;
                transition: 0.3s all ease;
                border-radius: 7px;
                font-weight: 700;
                background: transparent;
                &:hover, &:focus{
                    border: 1px solid #81c343;
                    background: #81c343;
                }
            }
        }
    }
    .menu_box{
        display: block;
        > .menu{
            li{
                border-top: 1px solid #3d4046;
                position: relative;
                &.b{
                    min-height: 44px;
                }
                .m_more{
                    height: 43px;
                    width: 43px;
                    background-color: $accent_color;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url('./../img/menu_more_arr.png');
                    @include anim;
                    &.o{
                        transform: rotate(90deg);
                    }
                }
                a{
                    color: #e8e8e8;
                    display: block;
                    padding: 15px 58px 15px 15px;
                    font-size: 10px;
                }
            }
            > ul{
                > li{
                    > ul{
                        padding-left: 15px;
                        display: none;
                        > li{
                            > ul{
                                padding-left: 15px;
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .social, .go_to_shop, .langs{
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
    }
    .social{
        margin-left: 15px;
    }
    .langs{
        margin-right: 0;
    }
    .go_to_shop{
        .more_btn{
            color: #e8e8e8;
        }
    }
}

.text-right{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
}